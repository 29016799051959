import React from "react";
import News from "../sections/News";

function Content() {
  return (
    <main>
      <News />
    </main>
  );
}

export default Content;
