import React, { Component } from "react";

class Request extends Component {
    constructor(props) {
        super();
        this.content = props;
    }

    render() {
        return (
            <section className="section reveal fade-left request" id="request">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-full">
                            <div className="content is-large block is-size-1">
                                <h2 className="has-text-centered">
                                    {this.content.section7}
                                </h2>
                                <div className="columns is-centered">
                                    <div className="column is-1"></div>
                                    <div className="column is-4">
                                        <img src="./images/avatar4.jpg" alt=""/>
                                    </div>
                                    <div className="column is-1"></div>
                                    <div className="column is-5 is-size-5">
                                        <form id="request-form">
                                            <p><b>{this.content.request}</b>:</p>
                                            <div className="field">
                                                <label className="label">{this.content.song}
                                                    <div className="control">
                                                        <input className="input is-large" name="song" type="text" placeholder="Señorita" />
                                                    </div></label>
                                            </div>
                                            <div className="field">
                                                <label className="label">{this.content.artist}
                                                    <div className="control">
                                                        <input className="input is-large" name="artist" type="text" placeholder="Shawn Mendes, Camila Cabello" />
                                                    </div></label>
                                            </div>
                                            <div className="field">
                                                <label className="label">{this.content.requestedby}
                                                    <div className="control">
                                                        <input className="input is-large" name="requested" type="text" placeholder="Daniela and Adrian" />
                                                    </div></label>
                                            </div>
                                            <input className="button is-primary is-large songrequest" type="submit" value={this.content.submit}/>
                                        </form>
                                    </div>
                                    <div className="column is-1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Request;
