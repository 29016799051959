import React from "react";
import Hero from "../elements/HeroAAP";

function Header() {
  return (
    <header>
      <Hero />
    </header>
  );
}

export default Header;
